import { Component, OnInit, AfterViewInit, ElementRef } from "@angular/core";
declare const gapi: any;
@Component({
  selector: "app-google-login",
  templateUrl: "./google-login.component.html",
  styleUrls: ["./google-login.component.scss"],
})
export class GoogleLoginComponent implements AfterViewInit {
  public gapi: any;
  public userLoggedIn: boolean;
  private clientId: string =
    "15976367730-naau6gs3p554q5u0t3m9ufgoa8i512aa.apps.googleusercontent.com";
  private scope = ["profile", "email"].join(" ");
  public auth2: any;

  public googleInit() {
    gapi.load("auth2", () => {
      this.auth2 = gapi.auth2.init({
        client_id: this.clientId,
        cookiepolicy: "single_host_origin",
        scope: this.scope,
        // hosted_domain: "state.ca.gov",
      });
      this.attachSignin(this.element.nativeElement.firstChild);
    });
  }

  public attachSignin(element) {
    this.auth2.attachClickHandler(
      element,
      {},
      (googleUser) => {
        let profile = googleUser.getBasicProfile();
        // console.log("Token || " + googleUser.getAuthResponse().id_token);
        console.log("ID: " + profile.getId()); // Do not send to your backend! Use an ID token instead.
        console.log("Name: " + profile.getName());
        console.log("Image URL: " + profile.getImageUrl());
        console.log("Email: " + profile.getEmail()); // This is null if the 'email' scope is not present.
        this.userLoggedIn = true;
      },
      function (error) {
        console.log(JSON.stringify(error, undefined, 2));
      }
    );
  }
  public signOut() {
    var auth2 = gapi.auth2.getAuthInstance();
    auth2.signOut().then(function () {});
    this.userLoggedIn = false;
  }
  public onFailure() {
    console.error("Sign in has failed!");
  }
  constructor(private element: ElementRef) {
    console.log("ElementRef: ", this.element);
  }

  ngAfterViewInit() {
    this.googleInit();
  }
}

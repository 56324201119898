import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Location } from "@angular/common";

@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.scss"],
})
export class NavigationComponent implements OnInit {
  public show: boolean = true;
  public routerLinkPath: string;

  // Conditionally toggles the hamburger color from white to black and vice versa
  ega_colorChange() {
    if (this.show) {
      return "black";
    }
    return "grey";
  }
  ega_toggle() {
    this.show = !this.show;
    this.show == false
      ? this.router.navigate(["/about"])
      : this._location.back();
  }

  constructor(public router: Router, private _location: Location) {}

  ngOnInit() {}
}

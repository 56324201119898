import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-about",
  templateUrl: "./about.component.html",
  styleUrls: ["./about.component.scss"]
})
export class AboutComponent implements OnInit {
  public imgSrc: any;
  constructor() {}

  ngOnInit() {
    this.imgSrc = "../../assets/images/Rotechie-original-png.png";
  }
}

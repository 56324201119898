import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { MainComponent } from "./pages/main/main.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";
import { ColorPaletteComponent } from "./pages/color-palette/color-palette.component";
import { NavigationComponent } from "./navigation/navigation.component";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { AboutComponent } from "./navigation/about/about.component";
import { FormsModule } from "@angular/forms";
import { LoginComponent } from "./navigation/login/login.component";
import { GoogleLoginComponent } from "./navigation/google-login/google-login.component";
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';


export function language_css(): Function {
  return function language_css() { import('highlight.js/lib/languages/css') };
}
export function language_html(): Function {
  return function language_html() { import('highlight.js/lib/languages/xml') };
}
export function language_js(): Function {
  return function language_js() { import('highlight.js/lib/languages/javascript') };
}

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    ColorPaletteComponent,
    NavigationComponent,
    AboutComponent,
    LoginComponent,
    GoogleLoginComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatButtonModule,
    MatCheckboxModule,
    FormsModule,
    NgbModule,
    HighlightModule
  ],

  providers: [{
    provide: HIGHLIGHT_OPTIONS,
    useValue: {
      languages: {
        css: language_css,
        html: language_html,
        js: language_js,

        //css: function () { import('highlight.js/lib/languages/css') },
        //html: function () { import('highlight.js/lib/languages/xml') },
        //js: function () { import('highlight.js/lib/languages/javascript') }
      }
    }
  }],
  bootstrap: [AppComponent],
})
export class AppModule { }

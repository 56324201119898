import { Router } from "@angular/router";
import { Component } from "@angular/core";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  title = "rotechie2";

  constructor(public _router: Router) {}
}

import { ConservaPeperoniComponent } from "./leisure-charcuterie-module/recipes/conserva-peperoni/conserva-peperoni.component";
import { RecipesComponent } from "./leisure-charcuterie-module/recipes/recipes.component";
import { ColorPaletteComponent } from "./pages/color-palette/color-palette.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { MainComponent } from "./pages/main/main.component";
import { AboutComponent } from "./navigation/about/about.component";

const routes: Routes = [
  {
    path: "leisure-programming",
    loadChildren: () =>
      import("./leisure-programming-module/leisure-programming.module").then(
        (m) => m.LeisureProgrammingModule
      ),
  },
  { path: "", component: MainComponent },
  { path: "main", component: MainComponent },
  { path: "home", component: MainComponent },
  { path: "color", component: ColorPaletteComponent },
  { path: "about", component: AboutComponent },
  {
    path: "leisure-gardening",
    loadChildren: () =>
      import("./leisure-gardening-module/leisure-gardening-module.module").then(
        (m) => m.LeisureGardeningModuleModule
      ),
  },
  {
    path: "professional-experience",
    loadChildren: () =>
      import(
        "./professional-experience-module/professional-experience-module.module"
      ).then((m) => m.ProfessionalExperienceModuleModule),
  },
  {
    path: "leisure-photography",
    loadChildren: () =>
      import(
        "./leisure-photography-module/leisure-photography-module.module"
      ).then((m) => m.LeisurePhotographyModuleModule),
  },
  {
    path: "leisure-threedprinting",
    loadChildren: () =>
      import(
        "./leisure-threedprinting-module/leisure-threedprinting-module.module"
      ).then((m) => m.LeisureThreedprintingModuleModule),
  },
  {
    path: "leisure-charcuterie",
    loadChildren: () =>
      import(
        "./leisure-charcuterie-module/leisure-charcuterie-module.module"
      ).then((m) => m.LeisureCharcuterieModuleModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
